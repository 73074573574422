import React from 'react';
import { v4 as uuid } from 'uuid';
import Img from "gatsby-image"

import "./style.scss";

const What = ({ items, heading, background }) => {
  return (
    <section className="what">
      <div className="container">
        <h2 className="what__heading heading--big heading--center heading--dash" >{heading}</h2>
        <div className="what__wrapper">
          <div className="what__img">
            <Img fluid={background.imageFile.childImageSharp.fluid} />
          </div>
          {items.map((item, index) => (
            <div className={`what__item what__item--${index}`} key={uuid()}>
              <div className="what__item__description" dangerouslySetInnerHTML={{ __html: item.description }}></div>
              <div className="what__item__img">
                <Img fluid={item.img.imageFile.childImageSharp.fluid} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default What
