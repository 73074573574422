import React from 'react';
import Img from "gatsby-image"
import "./style.scss"


const Pictogram = ({ img, description, heading }) => {
  return (
    <div className="pictogram">
      <div className="pictogram__img">
        <Img fluid={img.imageFile.childImageSharp.fluid} />
      </div>
      <div className="pictogram__content">
        <p className="pictogram__heading">{heading}</p>
        <p className="pictogram__descirption">{description}</p>
      </div>
    </div>
  )
}
export default Pictogram
