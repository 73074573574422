import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuid } from 'uuid';
import Img from "gatsby-image"
import Button from "../../button/Button";


import 'swiper/css';
import 'swiper/css/pagination';
import "./style.scss";


const SliderHero = ({ items }) => {
  return (
    <section className="sliderHero" >
      <Swiper
        // install Swiper modules
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        navigation={false}
        pagination={{ clickable: true }}
      >
        {items.map(item => (
          <SwiperSlide key={uuid()} className="sliderHero__item">
            <div className="sliderHero__item__desktop">
              <Img fluid={item.bgDesktop.imageFile.childImageSharp.fluid} />
            </div>
            <div className="sliderHero__item__mobile">
              <Img fluid={item.bgMobile.imageFile.childImageSharp.fluid} />
            </div>
            <div className="container">
              <div className="sliderHero__item__content">
                {item.heading && (<h2 className="sliderHero__item__heading heading--xl">{item.heading}</h2>)}
                {item.description && (<p className="sliderHero__item__para">{item.description}</p>)}
                {item.button && (
                  <div className="sliderHero__item__button">
                    <Button
                      target={item.button.target}
                      title={item.button.title}
                      url={item.button.url}
                    />
                  </div>)}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>

  )
};
export default SliderHero
