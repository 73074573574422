import React, { useState, useEffect, useRef } from "react"
import BlockCategory from "../../category-block/blockCategory"
import { Navigation, Controller, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "./style.scss"
import { withPrefix } from "gatsby"
import Left from "../../../images/slider_left.svg"
import Right from "../../../images/slider_right.svg"

const Categories = ({ items, button, heading }) => {
  const [swiper, setSwiper] = useState()
  const prevRef = useRef()
  const nextRef = useRef()
  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current
      swiper.params.navigation.nextEl = nextRef.current
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [swiper])

  return (
    <section
      className="productCategory"
      style={{
        backgroundImage: `url(${withPrefix("/images/product_cat_bg.png")})`,
        backgroundPosition: "left top",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="container">
        <div className="productCategory__wrapper">
          <h2 className="heading heading--big heading--center heading--dash">
            {heading}
          </h2>
        </div>
        <div className="productCategory__swiper">
          <div className="swiper-button swiper-button--left" ref={prevRef}>
            <Left />
          </div>
          <div className="container">
            <Swiper
              // install Swiper modules
              modules={[Navigation, Controller, Pagination]}
              slidesPerView={1}
              loop={true}
              spaceBetween={50}
              // pagination={{ clickable: true }}
              observer
              observeParents
              onSwiper={setSwiper}
              navigation={{
                prevEl: prevRef?.current,
                nextEl: nextRef?.current,
              }}
              breakpoints={{
                576: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1300: {
                  slidesPerView: 4,
                },
              }}
            >
              {items.map((item, index) =>
                item.image ? (
                  <SwiperSlide
                    key={`productCategory__item__${index}`}
                    className="productCategory__item"
                  >
                    <BlockCategory
                      img={item.image}
                      button={button}
                      heading={item.name}
                      url={item.uri}
                    />
                  </SwiperSlide>
                ) : (
                  ""
                )
              )}
            </Swiper>
          </div>
          <div className="swiper-button swiper-button--right" ref={nextRef}>
            <Right />
          </div>
        </div>
      </div>
    </section>
  )
}
export default Categories
