import React from 'react';
import Button from "../../button/Button";
import Img from "gatsby-image"
import "./style.scss"


const Test = ({ info, heading, description, button, image }) => {
  return (
    <section className="test">
      <div className="container">
        <div className="test__wrapper">
          <div className="test__column--image">
            <Img fluid={image.imageFile.childImageSharp.fluid} />
          </div>
          <div className="test__column--description">
            {heading && (<h2 className="test__column__heading heading--dash heading--big">{heading}</h2>)}
            {description && (<div className="test__column__para" dangerouslySetInnerHTML={{ __html: description }}></div>)}
            {button && (
              <div className="test__column__button">
                <Button
                  target={button.target}
                  title={button.title}
                  url={button.url}
                />
              </div>)}
            {info && (<div className="test__column__info" dangerouslySetInnerHTML={{ __html: info }}></div>)}
          </div>
        </div>
      </div>
    </section>
  )
}
export default Test
