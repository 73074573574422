import React from 'react';
import { NewsletterData } from './hook';
import "./style.scss"
import Img from "gatsby-image"
import { v4 as uuid } from 'uuid';
import NewsletterForm from './form';


const Newsletter = ({ lang }) => {
  const data = NewsletterData(lang);
  return (
    <section className="newsletter">
      <div className="container">
        <h2 className="newsletter__heading heading--dash heading--center heading--big">
          {data.heading}
        </h2>
        <div className="newsletter__description" dangerouslySetInnerHTML={{ __html: data.description }}></div>
        <div className="newsletter__wrapper grid grid--4">
          {data.pictograms.map(item => (
            <div className="newsletter__item" key={uuid()}>
              <div className="newsletter__item__img" >
                <Img fluid={item.image.imageFile.childImageSharp.fluid} />
              </div>
              <div className="newsletter__item__description" dangerouslySetInnerHTML={{ __html: item.description }}></div>
            </div>
          ))}
        </div>
        <NewsletterForm
          buttonText={data.buttonText}
          inputPlaceholder={data.inputPlaceholder}
          successText={data.successText}
          errorText={data.errorText}
          sendingText={data.sendingText}
          formId={data.formId}
        />
      </div>
    </section>
  )
}
export default Newsletter
