import React from "react"
import { useState, useEffect, useRef } from "react"
import { Navigation, Autoplay, Controller, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { v4 as uuid } from "uuid"
import Img from "gatsby-image"
import Stars1 from "../../images/stars1.svg"
import Stars2 from "../../images/stars2.svg"
import Stars3 from "../../images/stars3.svg"
import Stars4 from "../../images/stars4.svg"
import Stars5 from "../../images/stars5.svg"

import Quote from "../../images/quote.svg"
import Left from "../../images/slider_left.svg"
import Right from "../../images/slider_right.svg"

import "swiper/css"
import "swiper/css/navigation"
import "./style.scss"
import { isBrowser } from "../../utils/functions"

const playVideo = e => {
  if (isBrowser()) {
    const elementImg = e.target
    const elementImgWrapper = elementImg.closest(".heroes__column__img")
    const elementWrapperVideo = elementImg
      .closest(".heroes__column")
      .querySelector(".heroes__column__video")
    if (elementWrapperVideo) {
      const video = elementWrapperVideo.querySelector("iframe")
      const videoUrl = video.dataset.src
      video.src = videoUrl
      elementImgWrapper.style.zIndex = "-1"
    }
  }
}

const slideChange = e => {
  const prevElement = e.slides[e.previousIndex]
  const elementImgWrapper = prevElement.querySelector(".heroes__column__img")
  const video = prevElement.querySelector("iframe")
  if (video) {
    video.src = ""
    elementImgWrapper.style.zIndex = "10"
  }
}

const starIcon = icon => {
  switch (icon) {
    case "5":
      return <Stars5 />

    case "4":
      return <Stars4 />

    case "3":
      return <Stars3 />

    case "2":
      return <Stars2 />

    case "1":
      return <Stars1 />

    default:
      return <Stars5 />
  }
}

const Heroes = ({ items, heading }) => {
  const [swiper, setSwiper] = useState()
  const prevRef = useRef()
  const nextRef = useRef()
  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current
      swiper.params.navigation.nextEl = nextRef.current
      swiper.navigation.init()
      swiper.navigation.update()
    }
  }, [swiper])

  return (
    <section className="heroes">
      <div className="heroes__wrapper">
        {items.legth > 1 ? (
          <div className="swiper-button swiper-button--left" ref={prevRef}>
            <Left />
          </div>
        ) : (
          ""
        )}
        <Swiper
          // install Swiper modules
          modules={[Autoplay, Navigation, Controller, Pagination]}
          slidesPerView={1}
          navigation={false}
          pagination={{ clickable: true }}
          onSlideChange={e => {
            slideChange(e)
          }}
          observer
          observeParents
          onSwiper={setSwiper}
          breakpoints={{
            768: {
              pagination: false,
              navigation: {
                prevEl: prevRef?.current,
                nextEl: nextRef?.current,
              },
            },
          }}
        >
          {items.map(item => (
            <SwiperSlide key={uuid()} className="heroes__item">
              <div className="container">
                <div className="heroes__item__wrapper">
                  <div className="heroes__column heroes__column--video">
                    <div
                      className={`heroes__column__img ${
                        !item.video ? "noVideo" : ""
                      }`}
                      onClick={e => {
                        if (item.video) {
                          playVideo(e)
                        }
                      }}
                    >
                      <Img fluid={item.image.imageFile.childImageSharp.fluid} />
                    </div>
                    {item.video ? (
                      <div className="heroes__column__video">
                        <iframe
                          load="lazy"
                          width="680"
                          height="810"
                          data-src={`https://www.youtube.com/embed/${item.video}?autoplay=1&amp;rel=0&amp;controls=1`}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="heroes__column heroes__column--description">
                    <p
                      className="heroes__column__heading heading--dash heading--big"
                      dangerouslySetInnerHTML={{ __html: heading }}
                    ></p>
                    <div className="heroes__column__starts">
                      {starIcon(item.stars)}
                      {item.starsDescription}
                    </div>
                    <div className="heroes__column__quote">
                      <Quote />
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                    </div>
                    <p className="heroes__column__quote__name">{item.name}</p>
                    <p className="heroes__column__quote__position">
                      {item.who}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {items.legth > 1 ? (
          <div className="swiper-button swiper-button--right" ref={nextRef}>
            <Right />
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  )
}
export default Heroes
