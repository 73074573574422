import React from 'react';
import Link from 'gatsby-link';
import "./style.scss"
import Img from "gatsby-image"
import Button from '../button/Button';


const BlockCategory = ({ img, button, heading, url }) => {
  return (
    <div className="blockCategory">
      <Link to={url}>
        <Img fluid={img.imageFile.childImageSharp.fluid} />
      </Link>
      <h3 className="heading heading--normal">{heading}</h3>
      <Button title={button} url={url} />
    </div>
  )
}
export default BlockCategory
