import React from 'react';
import { InstagramData } from './hook';
import "./style.scss"
import Img from "gatsby-image"
import Link from 'gatsby-link';
import { v4 as uuid } from 'uuid';


const Instagram = ({ lang }) => {
  const data = InstagramData(lang);
  return (
    <section className="instagram">
      <div className="container">
        <h2 className="instagram__heading heading--dash heading--big">
          <Link to={data.heading.url} target={data.heading.target ? data.heading.target : '_self'}>
            {data.heading.title}
          </Link>
        </h2>
        <div className="instagram__description" dangerouslySetInnerHTML={{ __html: data.description }}></div>
        <div className="instagram__wrapper grid grid--4">
          {data.images.map(item => (
            <a className="instagram__img" key={uuid()} href={item.link ? item.link : data.heading.url} target="_blank">
              <Img fluid={item.image.imageFile.childImageSharp.fluid} />
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Instagram
