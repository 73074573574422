import React from 'react';
import { TrustpilotData } from './hook';
import "./style.scss"
import Img from "gatsby-image"
import Stars1 from "../../images/stars1.svg"
import Stars2 from "../../images/stars2.svg"
import Stars3 from "../../images/stars3.svg"
import Stars4 from "../../images/stars4.svg"
import Stars5 from "../../images/stars5.svg"


const Trustpilot = ({ lang, background, small }) => {

  const starIcon = (icon) => {
    switch (icon) {
      case 5:
        return <Stars5 />

      case 4:
        return <Stars4 />

      case 3:
        return <Stars3 />

      case 2:
        return <Stars2 />

      case 1:
        return <Stars1 />

      default:
        return <Stars5 />

    }
  }

  const data = TrustpilotData(lang);
  return (
    <>
      {small ? (
        <div className={'trustpilot trustpilot--small'} style={{ background: background }}>
          <div className="trustpilot__wrapper">
            <div className="trustpilot__star">
              {starIcon(data.numberOfStarts)}
            </div>
            <p className="trustpilot__stars__text">
              {data.textInProduct}
            </p>
            <a className="trustpilot__img" href={data.link.url} target="_blank">
              <Img fluid={data.logo.imageFile.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      ) : (
        <section className={`trustpilot`} style={{ background: background }}>
          <div className="container">
            <div className="trustpilot__wrapper">
              <a className="trustpilot__img" href={data.link.url} target="_blank">
                <Img fluid={data.logo.imageFile.childImageSharp.fluid} />
              </a>
              <div className="trustpilot__stars">
                <a className="trustpilot__starts__img" href={data.link.url} target="_blank">
                  <Img fluid={data.iconstarts.imageFile.childImageSharp.fluid} />
                </a>
                <p className="trustpilot__stars__text">
                  {data.text}
                </p>
              </div>
              <h2 className="trustpilot__heading">
                {data.heading}
              </h2>
            </div>
          </div>
        </section>
      )
      }
    </>
  )
}
export default Trustpilot
