import React from 'react';
import Img from "gatsby-image"
import "./style.scss"
import Quote from "../../../images/quote.svg"


const Diet = ({ heading, description, image, name, who }) => {
  return (
    <section className="diet">
      <div className="container">
        <div className="diet__wrapper">
          <div className="diet__column--description">
            {heading && (<h2 className="diet__column__heading heading--dash heading--big">{heading}</h2>)}
            {description && (
              <div className="diet__column__quote">
                <Quote />
                <div className="diet__column__para" dangerouslySetInnerHTML={{ __html: description }}></div>
              </div>
            )}
            {name && (
              <p className="diet__column__name">
                {name}
              </p>
            )}
            {who && (
              <p className="diet__column__position">
                {who}
              </p>
            )}
          </div>
          <div className="diet__column--image">
            <Img fluid={image.imageFile.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}
export default Diet
