import { useStaticQuery, graphql } from "gatsby"
export const NewsletterData = (lang) => {
  const data = useStaticQuery(
    graphql`
      query Newsletter {
        wpgraphql {
          themesOptionsPage {
            template {
              newsletter {
                buttonText
                description
                fieldGroupName
                heading
                inputPlaceholder
                successText
                sendingText
                errorEmail
                formId
                pictograms {
                  description
                  image {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 50) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageEn {
            template {
              newsletter {
                buttonText
                description
                fieldGroupName
                heading
                inputPlaceholder
                successText
                sendingText
                errorEmail
                formId
                pictograms {
                  description
                  image {
                    sourceUrl
                    databaseId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 50) {
                          base64
                          aspectRatio
                          src
                          srcSet
                          sizes
                          srcWebp
                          srcSetWebp
                        }
                      }
                    }
                  }
                }
              }
            } 
          }
        }
      }
    `
  )
  switch (lang) {
    case 'pl':
      return data.wpgraphql.themesOptionsPage.template.newsletter
    case 'en':
      return data.wpgraphql.themesOptionsPageEn.template.newsletter
    default:
      return data.wpgraphql.themesOptionsPage.template.newsletter
  }
}
