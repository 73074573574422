import { useStaticQuery, graphql } from "gatsby"
export const TrustpilotData = (lang) => {
  const data = useStaticQuery(
    graphql`
      query Trustpilot {
        wpgraphql {
          themesOptionsPage {
            template {
              trustpilot {
                heading
                text
                link {
                  target
                  title
                  url
                }
                numberOfStarts
                textInProduct
                iconstarts {
                  sourceUrl
                  databaseId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 210) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                        srcWebp
                        srcSetWebp
                      }
                    }
                  }
                }
                logo {
                  sourceUrl
                  databaseId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                        srcWebp
                        srcSetWebp
                      }
                    }
                  }
                }
              }
            }
          }
          themesOptionsPageEn {
            template {
              trustpilot {
                heading
                text
                link {
                  target
                  title
                  url
                }
                numberOfStarts
                iconstarts {
                  sourceUrl
                  databaseId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 210) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                        srcWebp
                        srcSetWebp
                      }
                    }
                  }
                }
                logo {
                  sourceUrl
                  databaseId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                        srcWebp
                        srcSetWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  switch (lang) {
    case 'pl':
      return data.wpgraphql.themesOptionsPage.template.trustpilot
    case 'en':
      return data.wpgraphql.themesOptionsPageEn.template.trustpilot
    default:
      return data.wpgraphql.themesOptionsPage.template.trustpilot
  }
}
