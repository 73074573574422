import React from 'react';
import "./style.scss"
import { v4 as uuid } from 'uuid';
import Pictogram from './Pictogram';


const Pictograms = ({ items }) => {
  return (
    <section className="pictograms">
      <div className="container">
        <div className="pictograms__wrapper grid grid--4">
          {items.map(item => (
            <Pictogram img={item.img} description={item.description} heading={item.heading} key={uuid()} />
          ))}
        </div>
      </div>
    </section>
  )
}
export default Pictograms
