import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { v4 as uuid } from 'uuid';
import Img from "gatsby-image"

import 'swiper/css';
import "./style.scss";

const Partners = ({ items, text }) => {
  const swiperRef = useRef();

  return (
    <section className="partners">
      <div className="container">
        <div className="partners__wrapper">
          <p className="partners__heading">
            {text}
          </p>
          <div className="partners__logotypes">
            <Swiper
              ref={swiperRef}
              // install Swiper modules
              modules={[Autoplay]}
              slidesPerView={2}
              navigation={false}
              pagination={false}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={3500}
              spaceBetween={0}
              breakpoints={{
                768: {
                  slidesPerView: 4,
                },
                1300: {
                  slidesPerView: 5,
                },
              }}
            >
              {items.map(item => (
                <SwiperSlide key={uuid()} className="partners__logotypes__item">
                  <div className="partners__logotypes__item__img">
                    <Img fluid={item.logo.imageFile.childImageSharp.fluid} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Partners
